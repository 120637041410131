<template>
  <div class="application">
    <!-- 主体 -->
    <div class="mainbody">
      <div class="mainbody-title">
        <p>请选择需要注册的类型</p>
      </div>
      <div class="mainbody-content">
          <router-link to="/perfectinfo">
        <div class="content-left">
          <p class="content-left-text">
            <span><a-icon type="user" /></span>&nbsp;
            <span>个人/卖家</span>
          </p>
          <p class="left-text1">
            <span>个人申请，并委托我方代办个体工商户进行合法交易 </span>
          </p>
        </div>
        </router-link>
        <router-link to="/buyer" class="right-content">
          <div class="content-right">
            <p class="content-right-text">
              <span><a-icon type="bank" /></span>&nbsp;
              <span>企业/买家</span>
            </p>
            <p class="right-text1">
              <span>已经拥有营业执照的用户，进行购买操作</span>
            </p>
          </div></router-link
        >
      </div>
      <!-- <div class="bottom-text">
        <div class="bottom-left">
          <p>
            税票交易的特殊性，目前只开放个人入驻，然后委托平台办理工商注册，注册成功后，开立对应的个体户店铺
          </p>
        </div>
        <div class="bottom-right">
          <p>
            税票交易的特殊性，目前只开放企业入驻，不支持自然人和个体户申请买家
          </p>
        </div>
      </div> -->
    </div>


  </div>
</template>

<script>

export default {
  components: {
  },
};
</script>
<style lang="less" scoped>
.application {
  margin-bottom: 100px; 
   max-width: 1440px;
   min-width: 990px; @media screen and (max-width: 1440px){
   padding: 0 15px;
   }
  margin: 20px auto;
  .mainbody {
    user-select: none;
    width: 100%;
    height: 621px;
    background-color: rgba(255, 255, 255, 1);
    .mainbody-title {
      width: 100%;
      height: 37px;
      font-size: 35px;
      color: #202020;
      font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑", sans-serif;
      font-weight: 700;
      padding: 115px 0 90px 0;
      text-align: center;
    }
    .mainbody-content,
    .bottom-text {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      .right-content {
        margin-left: 42px;
      }
      .content-left,
      .content-right {
        cursor: pointer;
        width: 417px;
        height: 217px;
        background-color: rgba(76, 125, 255, 1);
        border-radius: 10px;
        .content-left-text,
        .content-right-text {
          padding: 55px 0 0 0;
          font-size: 36px;
          color: #fdfcfc;
          text-align: center;
        }
        .left-text1,
        .right-text1 {
          padding: 55px 0 0 0;
          text-align: center;
          font-size: 14px;
          color: #e5e5e5;
        }
      }
    }
  }
}
</style>